<template>
    <div>
        <div class="header"><i @click="back"></i> 地址详情</div>
        <div id="container" class="map" style="width: 100%;height: calc(100vh - 3.5rem)"></div>
        <div class="footer">
            <div class="city">南宁市</div>
            <div class="address"><i></i>青秀区中柬路8号龙光世纪A座7楼707室</div>
            <a class="send" href="http://api.map.baidu.com/marker?location=22.814737,108.405273&title=南宁市&content=青秀区中柬路8号龙光世纪A座7楼707室&output=html&src=webapp.baidu.openAPIdemo   ">
                <div class="road"><i></i> 路线</div>
            </a>
        </div>
    </div>
</template>
<script>
import BMap from 'BMap'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
export default {
    setup() {
        const router = useRouter()

        const back = () => {
            router.go(-1);
        }

        onMounted(() => {
            setTimeout(() => {
                var map = new BMap.Map('container');
                map.centerAndZoom(new BMap.Point(108.405273,22.814737), 18);
                var point = new BMap.Point(108.405273,22.814737);    
                map.enableScrollWheelZoom(true);
                var marker = new BMap.Marker(point);
                map.addOverlay(marker);
                var opts = {
                    position: new BMap.Point(108.405273,22.814737), // 指定文本标注所在的地理位置
                    offset: new BMap.Size(-130, -100) // 设置文本偏移量
                };
                // 创建文本标注对象
                var label = new BMap.Label(
                    `南宁市 <br />
                    <p style="font-weight:normal;color:#666;font-size:12px">地址：青秀区中柬路8号龙光世纪A座7楼707室</p>
                    <b class="down"></b>`,
                    opts
                );
                // 自定义文本标注样式
                label.setStyle({
                    borderRadius: '5px',
                    padding: '10px',
                    fontSize: '14px',
                    width: '250px',
                    height: '50px',
                    lineHeight: '25px',
                    fontFamily: '微软雅黑'
                });
                map.addOverlay(label);
            }, 80);
        })

        return {
            back
        }
    },
}
</script>
<style>
    .map .BMapLabel {
        border: none !important;
        box-shadow: 0 0 6px -3px #000;
    }
</style>
<style lang="scss" scoped>
    .header {
        height: 1rem;
        line-height: 1rem;
        text-align: center;
        position: relative;
        i {
            position: absolute;
            left: 0.3rem;
            top: 0.3rem;
            width: 0.4rem;
            height: 0.4rem;
            background: url(../../assets/images/back.png) no-repeat;
            background-size: cover;
        }
    }
    .footer {
        padding: 0.3rem;
        padding-bottom: 0;
        height: 2.2rem;
        overflow: hidden;
        .city {
            font-weight: bold;
        }
        .address {
            font-size: 0.24rem;
            margin-top: 0.2rem;
            line-height: 0.4rem;
            padding-bottom: 0.3rem;
            border-bottom: 1px solid #f1f1f1;
            i {
                float: left;
                width: 0.4rem;
                height: 0.4rem;
                background: url(../../assets/images/position1.png) no-repeat;
                background-size: cover;
                margin-right: 0.1rem;
            }
        }
        .road {
            text-align: center;
            font-size: 0.28rem;
            line-height: 0.8rem;
            i {
                display: inline-block;
                vertical-align: top;
                margin-top: 0.25rem;
                margin-right: 2px;
                width: 0.25rem;
                height: 0.25rem;
                background: url(../../assets/images/road.png) no-repeat;
                background-size: cover;
            }
        }
        a {
            color: #333;
            text-decoration: none;
        }
    }
</style>